import React from 'react'

import GoogleMapComponent from './GoogleMapComponent' 

// Some default styles
const styles = {
  width: '100%',
  height: '536px'
}

class GoogleMapWrapper extends React.PureComponent {
  render() {
    return (
      <div style={styles}>
        <GoogleMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyBMW6QJMXGte-70zAm-1UkRqn6ClUxpXZM"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      </div>
    )
  }
}

export default GoogleMapWrapper