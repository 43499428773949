import React from "react"
import {
    Col,
    Container,
    Row,
    Form,
    Button,
    Modal,
    Spinner
} from 'react-bootstrap'
import classnames from "classnames";
import axios from "axios";
import { window } from "browser-monads";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faPhone, faFile, faIndustry } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'
import $ from 'jquery'
import Footer from "../components/footer"

import '../css/contact.css'
import Metatags from "../components/metatags"
import Navbar from "../components/navbar"
import MyMapComponent from '../components/GMaps/GoogleMapWrapper'

// const [key, setKey] = React.useState('home');

class Contato extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cNome: "",
            cEmail: "",
            cEmpresa: "",
            cTelefone: "",
            cMensagem: "",
            tNome: "",
            tEmail: "",
            tTelefone: "",
            tMensagem: "",
            cEmailError: false,
            tEmailError: false,
            cformEnabled: false,
            tformEnabled: false,
            messageModal: false,
            fullNameFocus: "",
            contatoOuTrabalhe: 'contato',
            curriculumFileName: 'Arquivos válidos: pdf doc docx e odt.  Máximo de 1MB.',
            curriculumFile: [],
            curriculumUploadEnabled: false,
            contactActive: true,
            workWithUsActive: false,
            modalCurriculum: false,
            sizeRemark: false
        }
    }

    changeValue = async (evt) => {
        this.setState({
            [evt.target.name]: evt.target.value
        }, () => {
            this.verifyEnable();
        });
    }

    changeEmailValue = async (evt) => {
        evt.persist()
        this.setState({
            [evt.target.name]: evt.target.value
        }, () => {
            this.verifyEmail(evt);
        });
    }

    verifyEnable = () => {
        if (this.state.contatoOuTrabalhe === 'contato') {
            if ((!this.state.cEmailError || this.state.cEmail ==='')  && this.state.cMensagem !== '') {
                this.setState({
                    cformEnabled: true
                })
            } else {
                this.setState({
                    cformEnabled: false
                })
            }
        } else {
            if ((!this.state.tEmailError || this.state.tEmail ==='')  && this.state.tMensagem !== '') {
                this.setState({
                    tformEnabled: true
                })
            } else {
                this.setState({
                    tformEnabled: false
                })
            }
        }
    }


    verifyEmailString(email) {
        const re = new RegExp('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$');
        if (re.test(email)) {
            return true;
        } else {
            return false;
        }
    }

    verifyEmail = (evt) => {
        let fieldEmail = evt.target.name + 'Error'
        if (this.verifyEmailString(this.state[evt.target.name]) || this.state[evt.target.name] === '') {
            this.setState({
                emailFocus: false,
                [fieldEmail]: false,
                formEnabled: true,
            }, () => {
                this.verifyEnable();
            })
        } else {
            this.setState({
                emailFocus: false,
                [fieldEmail]: true,
                formEnabled: false,
            }, () => {
                this.verifyEnable();
            })
        }
    }

    setTrabalhe = (evt) => {
        evt.preventDefault();
        if (this.state.contatoOuTrabalhe !== 'trabalhe') {
            $('#contato').slideToggle(1000);
            $('#trabalhe').slideToggle(1000);
            this.setState({
                contatoOuTrabalhe: 'trabalhe'
            })
        }
        this.setState({
            contactActive: false,
            workWithUsActive: true
        })
    }

    setContato = (evt) => {
        evt.preventDefault();
        if (this.state.contatoOuTrabalhe !== 'contato') {
            $('#trabalhe').slideToggle(1000);
            $('#contato').slideToggle(1000);
            this.setState({
                contatoOuTrabalhe: 'contato'
            })
        }
        this.setState({
            contactActive: true,
            workWithUsActive: false
        })
    }


    onFileSelectHandler = (evt) => {
        evt.preventDefault();
        if (evt.target.files[0].size <= 1048576) {
            let nameArray = evt.target.files[0].name.split('.')
            let fileExtension = nameArray[nameArray.length-1]
            if(['pdf', 'doc', 'docx', 'odt'].includes(fileExtension))
            this.setState({
                curriculumFile: evt.target.files[0],
                curriculumFileName: evt.target.files[0].name,
                sizeRemark: false
            })
        } else {
            this.setState({
                sizeRemark: true
            })
        }
    }

    uploadFile() {

        if (typeof this.state.curriculumFile.name !== 'undefined') {
            this.setState({
                modalCurriculum: true
            })
            const postTime = new Date();
            const postTimeString = postTime.getFullYear() + '-' + (postTime.getMonth() + 1) + '-' + postTime.getDate() + '-' + postTime.getHours() + '-' + postTime.getMinutes() + '-' + postTime.getSeconds() + '-';
            const fileNameArray = this.state.curriculumFile.name.split('.');
            const preventColisionFilename = postTimeString + 'curriculum.' + fileNameArray[fileNameArray.length - 1]
            const getPresignedPostData = new Promise(resolve => {
                const xhr = new XMLHttpRequest();

                // Set the proper URL here.
                const url = "https://ki964uir3i.execute-api.us-east-1.amazonaws.com/dev/%7Bproxy+%7D";

                xhr.open("POST", url, true);
                xhr.setRequestHeader("Content-Type", "application/json");
                xhr.send(
                    JSON.stringify({
                        fileName: preventColisionFilename,
                        fileType: this.state.curriculumFile.type
                    })
                );
                xhr.onload = function () {
                    resolve(JSON.parse(this.responseText));
                };
            });


            getPresignedPostData.then(response => {
                // Getting the url from response
                const url = response.fileUploadURL;
                axios({
                    method: "PUT",
                    url: url,
                    data: this.state.curriculumFile,
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then(res => {
                        console.log(res);
                        console.log(url);
                    })
                    .catch(err => {
                        console.log(err);
                    })
                    .then(res => {
                        this.sendMail(preventColisionFilename);
                        this.setState({
                            modalCurriculum: false
                        })
                    });
            });
        } else {
            this.sendMail('');
        }
    }

    sendMail = (fileName) => {
        let emailData;
        if (this.state.contatoOuTrabalhe === 'contato') {
            emailData = JSON.stringify({
                type: 'contact',
                name: this.state.cNome,
                email: this.state.cEmail,
                company: this.state.cEmpresa,
                phone: this.state.cTelefone,
                message: this.state.cMensagem
            })
        } else {
            emailData = JSON.stringify({
                type: 'work',
                name: this.state.tNome,
                email: this.state.tEmail,
                phone: this.state.tTelefone,
                message: this.state.tMensagem,
                curriculum: (this.state.curriculumFileName === 'Arquivos válidos: pdf doc docx e odt.  Máximo de 1MB.') ? '' : fileName
            })
        }
        var req = new XMLHttpRequest();
        req.open("POST", "https://fmohl6l9ek.execute-api.sa-east-1.amazonaws.com/DEV1", true);
        req.setRequestHeader("Content-Type", "application/json");
        req.addEventListener("load", function () {
            if (req.status < 400) {
                var response = JSON.parse(req.responseText)
                console.log(response.body)
                console.log(response.body.redirect)
                let { redirect } = response.body;
                console.log(typeof response)
                console.log(redirect)
                if (typeof window !== `undefined`) window.location.replace(window.origin + '/' + response.body.redirect);
            } else {
                console.log("Request failed: " + req.statusText);
            }
        });
        req.send(emailData);
        console.log(emailData)
    }

    removeFile = () => {
        this.setState({
            curriculumFileName: 'Arquivos válidos: pdf doc docx e odt.  Máximo de 1MB.',
            curriculumFile: [],
        })
    }

    submit = (evt) => {
        evt.preventDefault();
        this.uploadFile()
    }


    render() {
        let removeButton;
        if (this.state.curriculumFileName === 'Arquivos válidos: pdf doc docx e odt.  Máximo de 1MB.') {
            removeButton = ''
        } else {
            removeButton =
                <Col md={1}>
                    <Button variant="primary btn-sm" style={{ marginTop: "6px" }}
                        onClick={e => {
                            this.removeFile();
                        }}>
                        Remover
                    </Button>
                </Col>
        }

        return (
            <>

                <Modal show={this.state.modalCurriculum} >
                    <Modal.Body style={{ textAlign: 'center' }} ><h4>Upload do currículo&nbsp;<Spinner animation="border" variant="dark" /></h4></Modal.Body>
                </Modal>
                <Metatags
                    title="Maxipack"
                    description="Faça um contato conosco"
                    url="https://www.maxipack.com.br/contato/"
                    image="https://www.maxipack.com.br/maxipack.png"
                    keywords="terceirização, embalagens, manuseio, re-packer"
                />
                <Navbar />
                <div className="space-50" style={{ height: "50px" }} />
                <Container>
                    <Row style={{ marginBottom: "20px" }}>
                        <Col>
                            <Container>
                                <Row style={{ fontSize: "40px" }}>
                                    <Col>
                                        <a href="https://wa.me/5519999897007" target="_blank" rel="noopener noreferrer" >
                                            <Button variant="primary" className={'shadow-none'}>
                                                <FontAwesomeIcon icon={faWhatsapp} style={{ fontSize: "40px" }} />
                                            </Button>
                                        </a>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <Container>
                                <Row>
                                    <Col>
                                        <Button variant="primary" active={this.state.contactActive} onClick={this.setContato} className={'shadow-none'}>
                                            Entre em contato
                                        </Button>{' '}
                                        <Button variant="primary" active={this.state.workWithUsActive} onClick={this.setTrabalhe} className={'shadow-none'}>
                                            Trabalhe conosco
                                        </Button>
                                    </Col>
                                </Row>
                                <div style={{ height: "50px" }} />


                                <Row id="contato" >
                                    <Col className="mb-lg-auto" lg="12">
                                        <Form>
                                            <Form.Group controlId="formBasicNome">
                                                <Form.Label><FontAwesomeIcon icon={faUser} />&nbsp;Nome</Form.Label>
                                                <Form.Control
                                                    name="cNome"
                                                    type="text"
                                                    placeholder="nome"
                                                    onChange={this.changeValue}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label><FontAwesomeIcon icon={faEnvelope} />&nbsp;Email</Form.Label>
                                                <Form.Control
                                                    type="email" placeholder="email"
                                                    name="cEmail"
                                                    onChange={this.changeEmailValue}
                                                    onBlur={this.verifyEmail}
                                                    className={classnames({
                                                        "input-group-focus": this.state.emailFocus,
                                                        "input-group-error": this.state.cEmailError
                                                    })}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmpresa">
                                                <Form.Label><FontAwesomeIcon icon={faIndustry} />&nbsp;Empresa</Form.Label>
                                                <Form.Control
                                                    name="cEmpresa"
                                                    type="text"
                                                    placeholder="empresa"
                                                    onChange={this.changeValue} />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicTelefone">
                                                <Form.Label><FontAwesomeIcon icon={faPhone} />&nbsp;Telefone</Form.Label>
                                                <Form.Control
                                                    name="cTelefone"
                                                    type="text"
                                                    placeholder="telefone"
                                                    onChange={this.changeValue} />
                                            </Form.Group>
                                            <Form.Group controlId="formMenssagem">
                                                <Form.Label>Mensagem</Form.Label>
                                                <Form.Control
                                                    name="cMensagem"
                                                    as="textarea"
                                                    rows="3"
                                                    placeholder="mensagem"
                                                    onChange={this.changeValue} />
                                            </Form.Group>
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                disabled={!this.state.cformEnabled}
                                                onClick={e => {
                                                    this.submit(e);
                                                }}>
                                                Enviar
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>

                                <Row id="trabalhe" style={{ display: 'none' }}>
                                    <Col className="mb-lg-auto" lg="12">
                                        <Form>
                                            <Form.Group controlId="formBasicNome">
                                                <Form.Label><FontAwesomeIcon icon={faUser} />&nbsp;Nome</Form.Label>
                                                <Form.Control
                                                    name="tNome"
                                                    type="text"
                                                    placeholder="nome"
                                                    onChange={this.changeValue} />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicEmail">
                                                <Form.Label><FontAwesomeIcon icon={faEnvelope} />&nbsp;Email</Form.Label>
                                                <Form.Control
                                                    type="email" placeholder="email"
                                                    name="tEmail"
                                                    onChange={this.changeEmailValue}
                                                    onBlur={this.verifyEmail}
                                                    className={classnames({
                                                        "input-group-focus": this.state.emailFocus,
                                                        "input-group-error": this.state.tEmailError
                                                    })}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formBasicTelefone">
                                                <Form.Label><FontAwesomeIcon icon={faPhone} />&nbsp;Telefone</Form.Label>
                                                <Form.Control
                                                    name="tTelefone"
                                                    type="text"
                                                    placeholder="telefone"
                                                    onChange={this.changeValue} />
                                            </Form.Group>
                                            <Form.Group controlId="formMenssagem">
                                                <Form.Label>Mensagem</Form.Label>
                                                <Form.Control
                                                    name="tMensagem"
                                                    as="textarea"
                                                    rows="3"
                                                    placeholder="mensagem"
                                                    onChange={this.changeValue} />
                                            </Form.Group>
                                            <Form.Group controlId="formMenssagem">
                                                <Form.Label><FontAwesomeIcon icon={faFile} />&nbsp;Caso deseje, envie seu currículo</Form.Label>
                                                <div className="upload-curriculum">
                                                    <input type="file" name="file" id="file" className="inputfile" accept=".docx,.pdf,.doc,.odt"
                                                        onChange={FileObject => this.onFileSelectHandler(FileObject)}
                                                    />
                                                    <label htmlFor="file">Clique para selecionar o arquivo</label>
                                                    <Container>
                                                        <Row>
                                                            {removeButton}
                                                            <Col>
                                                                <Form.Text className="" style={{ padding: "8px 0", color: (this.state.sizeRemark) ? "#bb4430" : "#17583f" }}>
                                                                    {this.state.curriculumFileName}
                                                                </Form.Text>
                                                            </Col>
                                                        </Row>
                                                    </Container>
                                                </div>
                                            </Form.Group>
                                            <Button variant="primary"
                                                disabled={!this.state.tformEnabled}
                                                onClick={e => {
                                                    this.submit(e);
                                                }}>
                                                Enviar
                                            </Button>
                                        </Form>
                                    </Col>
                                </Row>

                            </Container>
                        </Col>
                    </Row>
                    <div className="space-50" style={{ height: "50px" }} />
                    <Row>
                        <Col>
                            <MyMapComponent className="map-component" />
                        </Col>
                    </Row>
                </Container>
                <div style={{ height: "80px" }} />
                <Footer />
            </>
        )
    }
}

export default Contato
